<template>
    <NuxtImg :src="finalSrc" :alt="alt" :width="width" :height="height" placeholder :provider="provider"
        @error="handleError" v-if="!error" />
    <img :src="finalSrc" :alt="alt" v-else />
</template>

<script setup>
const props = defineProps({
    src: String,
    alt: String,
    width: Number,
    height: Number
})
const error = ref(false)
const provider = ref("")
const createImageSrc = () => {
    if (!props.src || props.src == "undefined") {
        provider.value = "s3"
        return '/assets/blog/thumbnails/pathway-card.png'
    }
    if (props.src.startsWith('http')) {
        return props.src
    }
    provider.value = "s3"
    return props.src
}

const finalSrc = ref(createImageSrc())

const handleError = (err) => {
    finalSrc.value = finalSrc.value.replace('/assets/blog/', '/assets/content/blog/')
    error.value = true
}

// const route = useRoute()
// const finalSrc = ref(props.src)
// const provider = ref(undefined)
// const imageExists = ref(false)
// const staticProvider = ref(false)

// const checkImage = async (url) => {
//     if (!import.meta.server && !import.meta.dev) {
//         return false
//     }
//     try {
//         const response = await fetch(url, { method: 'HEAD' });
//         return response.ok;
//     } catch (error) {
//         return false;
//     }
// }

// await setImage()

// async function setImage () {
//     /* NO IMAGE: OG Image fallback */
//     if (!props.src || props.src === "undefined") {
//         staticProvider.value = true
//         finalSrc.value = "/__og-image__/static" + route.path + "/og.png"
//         return;
//     }

//     imageExists.value = await checkImage(props.src)

//     if (!imageExists.value) {
//         provider.value = "s3"
//         imageExists.value = await checkImage(props.src)
//     }
// }
</script>
